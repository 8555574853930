
import { Component, Vue } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';

@Component({
   metaInfo(): MetaInfo {
      return {
         title: '',
         titleTemplate: 'Mobile',
      };
   },
})
export default class Index extends Vue {
   darkMode: boolean = false;

   created(): void {
      this.darkMode = this.$localStorage.getItem('mobileDark');
   }

   mounted(): void {
      this.$store.commit('hideLoading');
   }

   goTo(to: any) {
      this.$router.push(to);
   }

   changeMobileMode() {
      this.$localStorage.setItem('mobileDark', (this.darkMode = !this.darkMode));
   }
}
